.modal-backdrop.show {
  opacity: .85;
}

.modal-user-block {
  width: 100%;
  background: #16151E;
  padding: 76px 30px;
  position: relative;
}

.modal-user-auth {
  max-width: 400px;
}

.modal-title-block {
  text-align: center;
  font: 32px/32px "Intro-Bold", sans-serif;
  text-transform: uppercase;
  color: #F2F3F7;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}

.modal-user-form {
  width: 100%;
  .btn-wrap:nth-child(n+2), .btn-link-wrap:nth-child(n+2) {
    margin-top: 24px;
  }
}

.modal-user-block .btn {
  line-height: 36px;
  .btn-text {
    padding-left: 10px;
    padding-right: 10px;
    font-family: "Intro-Bold", sans-serif;
    letter-spacing: .5px;
  }
  &.btn-auth {
    max-width: 180px;
    width: 100%;
  }
  &.btn-reg {
    width: 100%;
    max-width: 400px;
  }
}

.btn-link {
  color: #1E55F4;
  text-decoration: underline;
  font: 400 16px/1.2 "Intro-Bold", sans-serif;
  letter-spacing: .5px;
  transition: all .2s linear;
  &:hover {
    text-decoration-color: transparent;
  }
}

.form-description-wrap {
  text-align: center;
  margin-top: 12px;
  font: 400 16px/1.2 "Intro-Bold", sans-serif;
  letter-spacing: .5px;
  color: #FFF;
  a {
    color: #1E55F4;
    text-decoration: underline;
    transition: all .2s linear;
    &[href^='tel:'], &[href^='mailto:'] {
      color: #FFF;
      text-decoration: none !important;
    }
    &:hover {
      text-decoration-color: transparent;
    }
  }
}

.btn-modal-close-2 {
  width: 18px;
  height: 18px;
  cursor: pointer;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background: transparent;
  transition: all .2s linear;
  opacity: 1;
  &:before, &:after {
    content: '';
    display: block;
    background: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  &:before {
    width: 100%;
    height: 2px;
  }
  &:after {
    width: 2px;
    height: 100%;
  }
}

.modal-user-block .btn-modal-close-2 {
  top: 20px;
  right: 20px;
  position: absolute;
}

.btn-modal-close-2:hover {
  opacity: .75;
}

.modal-user-reg {
  max-width: 520px;
}

.modal-user-drive {
  max-width: 520px;
  .form-description-wrap {
    font-size: 14px;
  }
}

.modal-user-form .btn {
  &.focus, &:focus {
    box-shadow: none;
  }
}

.bs-modal-user-form {
  width: 100%;
  display: block;
  input {
    &[type='text'].form-control, &[type='password'].form-control, &[type='email'].form-control, &[type='tel'].form-control {
      display: block;
      position: relative;
      width: 100%;
      background: #1F1D2B;
      text-align: left;
      height: 40px;
      transition: all .2s linear;
      font: 400 18px/18px "Intro-Bold", sans-serif;
      color: #FFF;
      padding: 0 8px;
      border: 1px solid #545050;
      outline: none !important;
      box-shadow: none !important;
    }
  }
  .form-group {
    font: 400 18px/18px "Intro-Bold", sans-serif;
    color: #FFF;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}

.modal-user-form .btn.btn-reg {
  width: 100%;
  max-width: 400px;
}

.bs-modal-user-form {
  .invalid-feedback {
    width: 100%;
    -webkit-pointer-events: none;
    pointer-events: none;
    color: #C50D00;
    font: 400 14px/14px "Intro-Bold", sans-serif;
    letter-spacing: .5px;
    transition: all .2s linear;
    text-align: left;
  }
  .btn.btn-default {
    text-align: center;
    width: 100%;
    cursor: pointer;
    border-radius: 300px;
    border: 2px solid #E60500;
    display: block;
    margin: 0 auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #FFF !important;
    transition: all .2s linear;
    background: transparent;
    padding: 0 10px;
    letter-spacing: .5px;
    font: 400 18px/36px "Intro-Bold", sans-serif;
    text-transform: uppercase;
    outline: none !important;
    box-shadow: none !important;
    &.btn-auth {
      max-width: 180px;
    }
    &:hover, &.fill {
      background: #E60500;
    }
    &:hover {
      background: #7B0203;
      border-color: #7B0203;
    }
  }
  .form-group.required input {
    background-image: url(../images/zilant/inp_star.svg);
    background-position: calc(100% - 5px) 5px;
    background-size: auto auto;
    background-repeat: no-repeat;
  }
}

.modal-user-block {
  .btn-wrap:nth-child(n+2) {
    margin-top: 20px;
  }
  .btn-link-wrap:nth-child(n+2) {
    margin-top: 30px;
  }
}

.form-auth-reg-block {
  margin-top: 30px;
  font: 400 16px/1.2 "Intro-Bold", sans-serif;
  letter-spacing: .5px;
  a {
    color: #1E55F4;
    text-decoration: underline;
    transition: all .2s linear;
    &:hover {
      color: #0056b3;
      text-decoration-color: transparent;
    }
  }
}

@media (max-width: 700px) {
  .modal-user-block {
    padding: 50px 20px;
  }
}

@media (max-width: 575px) {
  .modal-user-auth {
    margin: 0 auto;
  }
}

@media (max-width: 400px) {
  .form-description-wrap {
    font-size: 14px;
  }
  .modal-title-block {
    font-size: 26px;
  }
}